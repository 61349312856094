import { createTheme } from "@mui/material/styles"

const muiTheme = createTheme({
  root: {
    "& > *": {
      justifyContent: "center",
      display: "flex",
    },
  },
  palette: {
    primary: {
      main: "#692d3c",
    },
    secondary: {
      main: "#a84860",
    },
    text: {
      primary: "#444",
    },
  },
  typography: {
    fontFamily: `'Lora', serif;`,
    fontSize: 16,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.5rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h4: {
      fontSize: "1.75rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
    body1: {
      fontSize: "1.15rem",
      letterSpacing: "0.1rem",
    },
  },
})

export default muiTheme
